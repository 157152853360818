<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop doodle-display-box">
      <ConfirmationBox @action="action" :confirmation-text="confirmationText" :yes-text="yesText"
                       :no-text="noText" @close="$emit('toggle')" show-confirmation="true" v-show="!inProgress && !isCompleted"/>
      <Spinner v-show="inProgress" :message="inProgressMessage"/>
      <DoodleMessage :message="message"
                     :message-class="messageClass"/>
      <div class="has-text-centered margin-top-20px" v-if="isCompleted">
        <button class="button has-text-weight-semibold" @click="$emit('toggle')">Close</button>
      </div>
    </div>
  </SideBar>
</template>

<script>
import SideBar from '../Utils/SideBar'
import ConfirmationBox from './ConfirmationBox'
import { eventBus } from '../../assets/javascript/event-bus'
import Spinner from './Spinner'
import DoodleMessage from './DoodleMessage'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'confirmationSideBar',
  components: { DoodleMessage, Spinner, ConfirmationBox, SideBar },
  props: ['confirmationText', 'yesText', 'noText', 'notifyEvent', 'inProgressMessage', 'successMessage', 'failureMessage', 'refData'],
  data: function () {
    return {
      masterData: [],
      searchTerm: null,
      searchResult: [],
      inProgress: false,
      isCompleted: false,
      message: null,
      messageClass: 'has-text-success'
    }
  },
  mounted () {
    eventBus.$on(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION, () => {
      this.inProgress = false
      this.isCompleted = true
      this.message = this.successMessage
      this.messageClass = 'has-text-success'
    })

    eventBus.$on(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION, () => {
      this.inProgress = false
      this.isCompleted = true
      this.message = this.failureMessage
      this.messageClass = 'has-text-danger'
    })
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
    eventBus.$off(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
  },
  methods: {
    action () {
      this.inProgress = true
      this.message = null
      eventBus.$emit(this.notifyEvent, this.refData)
    }
  }
}
</script>
